<template>
  <div class="container flex">
    <img src="../../../assets/images/sale/mall/huodong_img.png" />
    <div class="img-right flex flex-align-center flex-pack-center">
      <el-carousel
        ref="carousel"
        arrow="never"
        :interval="5000"
        indicator-position="none"
        height="100%"
      >
        <el-carousel-item v-for="item in arr" :key="item">
          <div class="item">
            <div class="item-top flex">
              <img @click="goDesignZizhi" src="../../../assets/images/sale/mall/cp_img1.png" />
              <div class="img-right">
                <img @click="goDesignZizhi" src="../../../assets/images/sale/mall/Bitmap1.png" />
                <div class="name">赣州标琢家具设计有限公司</div>
                <div class="factory flex flex-align-center">
                  <span>2</span>报名工厂
                </div>
                <div
                  class="des"
                >洛蓝·好木家具是一家专业生产高档橡木套房家具的企业，拥有现代化企业经营理念，先进的设备生产线，一流的管理人才。企业自创办以来本着“以质为本"求实创新”的企业精神，了广大客户的支持与…</div>
              </div>
            </div>
            <div class="item-btm">
              <div class="flex">
                <span>合作工厂</span>
                <span>
                  <img src="../../../assets/images/sale/mall/xsj_log1-4.png" />
                </span>
                <span>
                  <img src="../../../assets/images/sale/mall/xsj-log2-4.png" />
                </span>
                <span>
                  <img src="../../../assets/images/sale/mall/xsj-log3-4.png" />
                </span>
                <span>
                  <img :src="staticUrl+'design/images/sale/mall/comp/duanrui_logo.jpg'" />
                </span>
                <span>查看更多</span>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>

      <!-- <div v-if="arr.length > 1" class="prev" @click="prev()"></div>
      <div v-if="arr.length > 1" class="next" @click="next()"></div> -->
    </div>
  </div>
</template>

<script>
import { STATIC_URL_PRE } from '@/config';
export default {
  data() {
    return {
      staticUrl:STATIC_URL_PRE,
      arr: [1, 2, 3],
      index: 0,
      translateX: 0
    };
  },
  methods: {
    prev() {
      this.$refs.carousel.prev();
    },
    next() {
      this.$refs.carousel.next();
    },
    goDesignZizhi() {
      this.$router.push("/zizhi/view/117")
    }
  }
};
</script>
<style scoped lang="less">
.el-carousel {
  width: 100%;
  height: 100%;
}
.container {
  width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(219, 225, 246, 1);
  > img {
    display: block;
    width: 300px;
    height: 480px;
  }
  > .img-right {
    width: 900px;
    height: 480px;
    position: relative;
    .prev,
    .next {
      width: 48px;
      height: 48px;
      background-size: 48px 48px;
      background-repeat: no-repeat;
      cursor: pointer;
      position: absolute;
      top: 50%;
      margin-top: -24px;
      z-index: 2;
    }
    .prev {
      background-image: url("../../../assets/images/sale/mall/js_new_Left arrow.png");
      left: 0;
    }
    .next {
      background-image: url("../../../assets/images/sale/mall/js_new_right arrow.png");
      right: 0;
    }
    .item {
      color: #808080;
      padding: 30px;
      width: 840px;
      flex-shrink: 0;
      margin: 30px;
      .item-top {
        > img {
          width: 260px;
          height: 260px;
          margin-right: 19px;
        }
        .img-right {
          img {
            width: 62px;
            height: 62px;
            display: block;
          }
          .name {
            color: #292929;
            font-size: 16px;
            margin: 10px 0;
          }
          .factory {
            font-size: 12px;
            span {
              width: 24px;
              height: 24px;
              line-height: 22px;
              text-align: center;
              border: 1px solid #365fe1;
              color: #365fe1;
              border-radius: 12px;
              margin-right: 9px;
            }
          }
          .des {
            padding: 10px;
            background-color: #f5f5f5;
            color: #b3b3b3;
            border-radius: 6px;
            width: 500px;
            margin-top: 10px;
          }
        }
      }
      .item-btm {
        margin-top: 40px;
        > div {
          span {
            width: 130px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #ccc;
            border-left: 0;
            overflow: hidden;
            &:first-child {
              border: 1px solid #dbe1f6;
              background-color: #f8f9ff;
              font-size: 24px;
              color: #292929;
            }
            &:last-child {
              border: 1px solid #dbe1f6;
              background-color: #f8f9ff;
              font-size: 16px;
              cursor: pointer;
              color: #5074ee;
            }
            img {
              width: 102px;
            }
          }
        }
      }
    }
  }
}
</style>