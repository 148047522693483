<template>
  <div class="container">
    <div class="list flex">
      <div v-for="(item, i) in arr" :key="i" class="item">
        <!-- <img :src="item.img" /> -->
        <div class="img">
          <div class="bg_img"
          :style="{backgroundImage: 'url(' +item.img+ ')'}"></div>
        </div>
        <div class="name">{{item.name}}</div>
        <el-popover
          placement="top-start"
          width="300"
          trigger="hover"
          :content="item.des">
          <div class="des ellipsis5" slot="reference">{{item.des}}</div>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      arr: [
        {
          name: "刘九生",
          img: require("../../../assets/images/sale/mall/sjs-1.png"),
          des:
            "标琢家具设计有限公司创始人，毕业于沈阳航空航天大学工业设计（家具设计方向），公司拥有15名来自深圳及各地的家具设计师，南康家具协会副会长，江西省工业设计协会理事"
        },
        {
          name: "粟登桂",
          img: require("../../../assets/images/sale/mall/sjs-2.png"),
          des:
            "粟登桂/恒度家具设计联合创始人/总经理，主创设计师/从事家具研发十余年，深刻洞悉市场变化规律/为消费者打造有温度的产品"
        },
        {
          name: "Toni Breton",
          img: require("../../../assets/images/sale/mall/sjs-3.png"),
          des:
            "HOMWO创意总监，毕业于EASD，曾任职于欧洲著名设计师 Lavernia Cienfuegas资深设计师"
        },
        {
          name: "Celeste Carratalá",
          img: require("../../../assets/images/sale/mall/sjs-4.png"),
          des:
            "毕业于EASD，曾任职于EASD工业设计学院教师、熟悉产品设计、消费者习惯调研、橡木管理等"
        },
        {
          name: "Alessandro Agrati",
          img: require("../../../assets/images/sale/mall/sjs-5.png"),
          des:
            "意大利米兰家具展艺术总监，设计师、品牌概念设计师、营销和传播战略家。"
        },
        {
          name: "托马索·马赛拉",
          img: require("../../../assets/images/sale/mall/sjs-6.png"),
          des:
            "2006年获得米兰理工学院工业设计硕士学位，TOmmasoMasere 在多个国家 (例如意大利巴西和中国)从事不同的项目，获得了工业设计的经验，还尝试了图形和展览设计。从2009年到2012年，他是深圳“设计巨蛋”的设计总监之一。2012年，他成立了 MaseraDesign.他的作品在重要杂志上发表，并入选：100%伦敦设计，深圳设计博物馆，佛罗伦萨设计周，深圳设计周。"
        }
      ]
    };
  }
};
</script>
<style scoped lang="less">
.ellipsis5 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
}
.container {
  width: 1200px;
  margin: 0 auto;
  .list {
    background-color: #fff;
    padding: 10px 0;
    .item {
      height: 314px;
      width: 300px;
      // img {
      //   display: block;
      //   margin: 0 auto;
      //   width: 140px;
      //   height: 140px;
      //   border-radius: 50%;
      // }
      .img {
        width: 140px;
        height: 140px;
        border-radius: 50%;
        overflow: hidden;
        margin: 10px auto 0;
      }
      .name {
        text-align: center;
        margin: 20px auto 10px;
        color: #292929;
        font-size: 16px;
        font-weight: bold;
      }
      .des {
        width: 180px;
        color: #b3b3b3;
        cursor: pointer;
        margin: 0 auto;
      }
    }
  }
}
</style>